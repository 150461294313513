import './App.scss';

import { Layout } from 'antd';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const App = () => {
  const Navbar = React.lazy(() => import('./components/navbar/navbar'));
  const Footer = React.lazy(() => import('./components/footer/footer'));
  const NoMatch = React.lazy(() => import('./components/no-match'));
  const HomeContainer = React.lazy(() => import('./components/home/home-container'));
  const SpielbetriebRanlgistenContainer = React.lazy(
    () => import('./components/spielbetrieb/ranglisten/spielbetrieb-ranglisten-container')
  );
  const SpielbetriebKategorienContainer = React.lazy(
    () => import('./components/spielbetrieb/kategorien/spielbetrieb-kategorien-container')
  );
  const AnmeldungsContainer = React.lazy(() => import('./components/anmeldung/anmeldung-container'));
  const UhtInfosContainer = React.lazy(() => import('./components/uht-infos/uht-infos-container'));
  const FotosContainer = React.lazy(() => import('./components/fotos/fotos-container'));
  const OrganisationContainer = React.lazy(() => import('./components/organisation/organisation-container'));
  const SponsorenContainer = React.lazy(() => import('./components/sponsoren/sponsoren-container'));
  const ImpressumContainer = React.lazy(() => import('./components/impressum/impressum-container'));
  const DownloadContainer = React.lazy(() => import('./components/download/download-container'));

  return (
    <BrowserRouter>
      <div className='app'>
        <Layout className='app-layout'>
          <div className='app-layout-top'>
            <Layout.Header>
              <div className='header-wrapper'>
                <Navbar />
              </div>
            </Layout.Header>
            <div className='content-wrapper'>
              <Layout.Content>
                <Routes>
                  <Route path='/' element={<HomeContainer />} />
                  <Route path='/spielbetrieb/kategorien' element={<SpielbetriebKategorienContainer />} />
                  <Route path='/spielbetrieb/ranglisten' element={<SpielbetriebRanlgistenContainer />} />
                  <Route path='/anmeldung' element={<AnmeldungsContainer />} />
                  <Route path='/uht-infos' element={<UhtInfosContainer />} />
                  <Route path='/fotos' element={<FotosContainer />} />
                  <Route path='/organisation' element={<OrganisationContainer />} />
                  <Route path='/sponsoring' element={<SponsorenContainer />} />
                  <Route path='/impressum' element={<ImpressumContainer />} />
                  <Route path='/download-registrations' element={<DownloadContainer />} />
                  <Route path='*' element={<NoMatch />} />
                </Routes>
              </Layout.Content>
            </div>
          </div>
          <div className='app-layout-bottom'>
            <Layout.Footer className='app-layout-footer'>
              <Footer />
            </Layout.Footer>
          </div>
        </Layout>
      </div>
    </BrowserRouter>
  );
};

export default App;
